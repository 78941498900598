import './App.css';
import { Row, Col, Image } from 'react-bootstrap'
import Tool from './Tool'
import Contact from './Contact'
import tool_1 from '../assets/tools/solidity.png'
import tool_2 from '../assets/tools/react.png'
import tool_3 from '../assets/tools/nodejs.png'
import tool_4 from '../assets/tools/hardhat.png'
import tool_5 from '../assets/tools/chai.png'
import raffle from '../assets/project_pages/raffle/raffle_single.jpg'
import nftairdrop from '../assets/project_pages/porkers/nftairdrop.jpg'
import vrf from '../assets/project_pages/porkers/vrf.png'
import unit_tests from '../assets/project_pages/gelatoverse/unit_tests.jpg'

const Project_Raffle = () => {
  return (
    <Row className="projectPage">

      {/* HEADER */}
      <Row className="projectPageHeader projectPageHeaderRaffle">
      </Row>

      {/* CONTENT */}
      <Row className="projectPageContent">

        {/* TITLE */}
        <Row className="projectPageTitle">
          <div>
            ETC Raffle
          </div>
        </Row>
        <Row className="projectPageSubtitle">
          <div>
            Token-Based Raffle
          </div>
        </Row>

        {/* PROPERTIES */}
        <Row className="projectPageProperties">
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Project</div>
              <div className="projectPagePropertyDesc">Raffle with custom Token rewards</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Category</div>
              <div className="projectPagePropertyDesc">Randomness project on Ethereum Classic</div>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">For who?</div>
              <div className="projectPagePropertyDesc">A team on the ETC network</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Role</div>
              <div className="projectPagePropertyDesc">Blockchain Developer</div>
            </Row>
          </Col>
        </Row>


        {/* TOOLS */}
        <Row className="tools mx-0">
            <Row className=" mx-0">
                <div className="sectionSubtitle mx-0 reveal">
                    Used Tools
                </div>
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_1} title="Solidity" />
                <Tool logo={tool_2} title="ReactJs" />
                <Tool logo={tool_3} title="NodeJs" />
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_4} title="Hardhat" />
                <Tool logo={tool_5} title="Chai" />
                {/* <div className="m-0 p-0 d-none d-lg-block"><Tool className="" logo={tool_6} title="Slither" /></div> */}
            </Row>
        </Row>

        {/* DESCRIPTION */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-8 p-0">
            <div className="projectPageDescriptionTitle">
              The Project
            </div>
            <div className="projectPageDescriptionText">
              This project is a raffle dApp on the Ethereum Classic network. 11 Players enter the raffle by betting a certain amount of a custom token. After all slots are filled, one player is randomly designated to win 10x the entry price. A percentage of the rest gets burned and the rest is sent back to the team. <br/><br/>

              This project is particularly interesting due to the fact that it was made on the Ethereum Classic Network that do not have yet Oracle like on Ethereum in order to generate random numbers. Generating fair and provable randomness without an Oracle is more challenging. The solution we came up with include the use of a central server to generate a random winner and the use of the provenance hash algorithm.
            </div>
          </Col>
          <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={raffle} className="projectPageImageElement" /></div>
            </div>
          </Col>
        </Row>

        {/* RANDOM MINTING */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={vrf} className="projectPageImageElement" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-8 p-0">
              <div className="projectPageDescriptionTitle">
                The provenance Hash solution
              </div>
              <div className="projectPageDescriptionText">
              In the context of verifying the integrity of a random number, a provenance hash algorithm can be used to generate a unique identifier (hash value) for the number that can be used to verify its integrity. This can be useful in situations where the random number is being used as part of a secure process, such as generating a cryptographic key or selecting a winner in a lottery. <br/><br/>

              To verify the integrity of the random number using a provenance hash algorithm, the following steps can be taken:<br/><br/>

              <ol>
                <li>Generate the hash value for the random number using the provenance hash algorithm on the server.</li>
                <li>Store the hash value along with the random number in the smart contract</li>
                <li>After the winner is picked, when the integrity of the random number needs to be verified, re-calculate the hash value using the provenance hash algorithm and the stored random number.</li>
                <li>Compare the newly calculated hash value to the stored hash value. If the values match, then the integrity of the random number has been verified. If the values do not match, then the random number has been tampered with or otherwise altered.</li>
              </ol>
              
              Provenance hash algorithms are designed to be computationally infeasible to reverse, meaning that it is practically impossible to determine the original input data (in this case, the random number) from the hash value alone. This makes them useful for verifying the integrity of data without requiring access to the original data itself.

              </div>
            </Col>
          </Row>
        </Row>

        {/* UNIT TESTS */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle">
                Unit Tests
              </div>
              <div className="projectPageDescriptionText">
                Writing automated tests when building smart contracts is of crucial importance, as your user's money is what's at stake. <br/> <br/>
                The complexity in this project necessited a very good coverage in unit tests. Manipulating time in these unit tests ensured that every situation could be covered and make sure that the code was behaving as wished.
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={unit_tests} className="projectPageImageElement" /></div>
              </div>
            </Col>
          </Row>
        </Row>


      </Row>
      <Contact className="mt-5" text={"You see something you like? Don't hesitate to reach out to get a Quote estimation for FREE."}/>
    </Row>
  );
}

export default Project_Raffle;
