import './App.css';
import { Row, Col, Image } from 'react-bootstrap'
import Tool from './Tool'
import Contact from './Contact'
import tool_1 from '../assets/tools/solidity.png'
import tool_2 from '../assets/tools/react.png'
import tool_3 from '../assets/tools/nodejs.png'
import tool_4 from '../assets/tools/hardhat.png'
import tool_5 from '../assets/tools/chai.png'
import tool_6 from '../assets/tools/slither.png'
import nfts from '../assets/project_pages/aka_thailand/nfts.png'
import unit_tests from '../assets/project_pages/gelatoverse/unit_tests.jpg'

const Project_AKA_Thailand = () => {
  return (
    <Row className="projectPage">

      {/* HEADER */}
      <Row className="projectPageHeader projectPageHeaderAKAThailand">
      </Row>

      {/* CONTENT */}
      <Row className="projectPageContent">

        {/* TITLE */}
        <Row className="projectPageTitle">
          <div>
            AKA Thailand
          </div>
        </Row>
        <Row className="projectPageSubtitle">
          <div>
            Smart Contract Auditing
          </div>
        </Row>

        {/* PROPERTIES */}
        <Row className="projectPageProperties">
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Project</div>
              <div className="projectPagePropertyDesc">ERC721A Smart Contract Auditing</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Category</div>
              <div className="projectPagePropertyDesc">ERC721A NFT Project</div>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">For who?</div>
              <div className="projectPagePropertyDesc">AKA Thailand in collaboration with Mike Swick, famous former UFC performer</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Role</div>
              <div className="projectPagePropertyDesc">Smart Contract Auditor</div>
            </Row>
          </Col>
        </Row>


        {/* TOOLS */}
        <Row className="tools mx-0">
            <Row className=" mx-0">
                <div className="sectionSubtitle mx-0 reveal">
                    Used Tools
                </div>
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_1} title="Solidity" />
                <Tool logo={tool_4} title="Hardhat" />
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_5} title="Chai" />
                <Tool className="" logo={tool_6} title="Slither" />
            </Row>
        </Row>

        {/* DESCRIPTION */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-8 p-0">
            <div className="projectPageDescriptionTitle">
              The Project
            </div>
            <div className="projectPageDescriptionText">
              AKA Thailand is a MMA fighting club in Thailand. Former UFC performer Mike Swick creates a NFT collection with astonishing art that is used as a closed membership access. <br/><br/>

              Technically, an ERC721A smart contract was used to save gas fees when minting multiple NFTs at once. A MerkleTree was implemented for the whitelist in order to save a large amount of addresses without gas problems. There was a presale phase as well as a reveal function.
            </div>
          </Col>
          <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={nfts} className="projectPageImageElement" /></div>
            </div>
          </Col>
        </Row>

        {/* AUDITING PROCESS */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={unit_tests} className="projectPageImageElementMid" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle alignRightOnDesktop">
              Our Auditing Process 
              </div>
              <div className="projectPageDescriptionText">
              Our auditing process looks like the following: <br/> <br/>
                1. Run tests <br/>
                2. Read specs/docs <br/>
                3. Run fast tools (like slither, linters, static analysis, etc) <br/>
                4. Manual Analysis <br/>
                5. Run slow tools (like echidna, manticore, symbolic execution, MythX) <br/>
                6. Discuss (and repeat steps as needed) <br/>
                7. Write report

                <br/><br/>In the end of the auditing process you will receive a .PDF report containing our analysis. You will see the found errors, categorized in severity, as well as notes regarding the overall behavior of the code in comparison to your requirements.

                <br/><br/><a className="offerLink" href={"/auditing-process"}>Check our auditing process in more detail &#62;</a>
              </div>
            </Col>
          </Row>
        </Row>



      </Row>
      <Contact className="mt-5" text={"You see something you like? Don't hesitate to reach out to get a Quote estimation for FREE."}/>
    </Row>
  );
}

export default Project_AKA_Thailand;
