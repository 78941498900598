import { Image, Row, Col } from 'react-bootstrap'
import star from '../assets/star.png'

const Review = ({name, country, text, light}) => {
    return (
        <Row className={light ? "review offerLightBackground reveal" : "review revealRight"}>
            <Row className="m-0 p-0">
                <Col className="col-12 p-0 reviewStarsDiv d-flex justify-content-start align-items-center">
                        <Image src={star} className="reviewStarsImage" />
                        <Image src={star} className="reviewStarsImage" />
                        <Image src={star} className="reviewStarsImage" />
                        <Image src={star} className="reviewStarsImage" />
                        <Image src={star} className="reviewStarsImage" />
                </Col>
                <Col className="col-12 p-0">
                    <div className="reviewName">{name}</div>
                    <div className="reviewCountry">{country}</div>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <div className="reviewText">{text}</div>
            </Row>
        </Row>
    );
}
export default Review