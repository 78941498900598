import { Image, Row, Col } from 'react-bootstrap'

const Project = ({logo, title, description, linkText, link, light, targetBlank}) => {
    return (
        <Row className={light ? "project offerLightBackground reveal" : "project revealRight"}>
            <Col className="col-12 col-lg-8 p-0">
                <div className="projectTitle">{title}</div>
                <div className="projectDescription">{description}</div>
                <div className="linkTextDescription">
                    {targetBlank ? (
                        <a href={link} className="projectLink" target="_blank">{linkText}</a>
                    ) : (
                        <a href={link} className="projectLink">{linkText}</a>
                    )}
                </div>
            </Col>
            <Col className="col-12 col-lg-4 p-0">
                <div className="projectImage">
                    {targetBlank ? (
                        <a href={link} target="_blank"><Image src={logo} className="projectLogo" /></a>
                    ) : (
                        <a href={link}><Image src={logo} className="projectLogo" /></a>
                    )}
                </div>
            </Col>
        </Row>
    );
}
export default Project