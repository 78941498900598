import { Image, Row, Col, Button } from 'react-bootstrap'
import Review from './Review'

const Reviews = () => {
    return (
        <Row className="reviews mx-0" id="reviews">
            <Row className="m-0 p-0">
                <div className="sectionTitle reveal">
                    What Clients say about us
                </div>
            </Row>
            <Row className="reviewItems m-0">
                <Review light name="Thomas" country="United Kindgom" text="The talent at Linko Labs really know their stuff well! They're the best in the industry. 10/10 perfect experience, would highly recommend! Very professional, responsive and knowledgeable. Looking forward to working with Linko Labs again!" />
                <Review name="Larine" country="United States" text="Alexis at Linko Labs is extremely polite and professional. As you know the NFT world is still extremely new and fast pace. Everyday is something new to add to the challenge. He will try to understand exactly what you need for your project. He appears to be honest and knowledgeable. I look forward to working  with him in the future." />
                <Review light name="Joao" country="Brazil" text="Linko Labs is the best Web3 agency I've interacted with. Blazing fast delivery, excellent communication and a reasonable price. They were flexible when requirements had to be changed and adapted well. Will definitely work again in the future and recommend." />
            </Row>
        </Row>
    );
}
export default Reviews