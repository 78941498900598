import { Image, Row, Col, Button } from 'react-bootstrap'
import Axios from 'axios'

const Footer = ({projectPage}) => {

    const onClickSchedule = async () => {
        console.log("onClickSchedule")
        
        // const apiVersion = "v15.0"
        // const pixelId = "1153152622238569"
        // const apiUrl = "https://graph.facebook.com/" + apiVersion + "/" + pixelId + "/events?access_token=" + process.env.REACT_APP_FACEBOOK_CONVERSION_API_TOKEN
        // const payload = {
        //     "data": [
        //         {test_event_code: "TEST35761"}
        //     ]
        // }

        // console.log("Sending Post request to " + apiUrl)
        // Axios.post(apiUrl, payload).then((response) => {
        //     const serverResultType = response.data
        //     console.log("Server result type: " + serverResultType)
        // }, {
        //     headers: {
        //         "access_token": process.env.REACT_APP_FACEBOOK_CONVERSION_API_TOKEN
        //     }
        // })
        window.gtag_report_conversion_call()
    }

    return (
        <Row className="footer mx-0">
            <Row className="p-0 mx-0">
                <Col className="p-0 col-12 col-lg-8">
                    <Row className="p-0 mx-0">
                        <div className="footerTitle">
                            Linko Labs
                        </div>
                    </Row>
                    <Row className="p-0 mx-0">
                        <div className="footerDescription">
                        </div>
                    </Row>
                </Col>
                <Col className="p-0">
                    <div className="footerSubtitle">
                        Menu
                    </div>
                    
                    {projectPage ? ( 
                        <>
                            <div className="footerMenuElement">
                                <a href="/" className="footerLink">Home</a>
                            </div>
                            {/* <div className="footerMenuElement">
                                <a href="/#offers" className="footerLink">Offers</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="/#projects" className="footerLink" id="projectsLink">Projects</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="/#reviews" className="footerLink">Reviews</a>
                            </div> */}
                        </>
                    ) : ( 
                        <>
                            <div className="footerMenuElement">
                                <a href="#top" className="footerLink">Home</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="#offers" className="footerLink">Offers</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="#projects" className="footerLink" id="projectsLink">Projects</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="#reviews" className="footerLink">Reviews</a>
                            </div>
                        </>
                    )}
                </Col>
                <Col className="p-0">
                    <div className="footerSubtitle">
                        Stay in touch
                    </div>
                    {projectPage ? ( 
                        <>
                            <div className="footerMenuElement">
                                <a href="https://twitter.com/MarshiellLinko" target="_blank" className="footerLink">Twitter</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="https://github.com/alexisdavidson/" target="_blank" className="footerLink">GitHub</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="#contact" id="ex" className="footerLink">Contact Us</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="https://calendly.com/alexisdavidson/15min" target="_blank" className="footerLink" onClick={onClickSchedule} >Schedule a Call</a>
                            </div>
                        </>
                    ) : ( 
                        <>
                            <div className="footerMenuElement">
                                <a href="https://twitter.com/MarshiellLinko" target="_blank" className="footerLink">Twitter</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="https://github.com/alexisdavidson/" target="_blank" className="footerLink">GitHub</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="#contact" id="ex" className="footerLink">Contact Us</a>
                            </div>
                            <div className="footerMenuElement">
                                <a href="https://calendly.com/alexisdavidson/15min" target="_blank" className="footerLink" onClick={onClickSchedule} >Schedule a Call</a>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
            <Row className="p-0 mx-0">
                <div className="footerCopyright">
                    Copyright © 2023 Linko Labs. All rights reserved.
                </div>
            </Row>
        </Row>
    );
}
export default Footer