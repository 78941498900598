import { Image, Row, Col, Button } from 'react-bootstrap'
import Project from './Project'
import logo_1 from '../assets/projects/raffle.png'
import logo_2 from '../assets/projects/aka_thailand.png'
import logo_3 from '../assets/projects/gelatoverse.png'
import logo_4 from '../assets/projects/mystic.png'
import logo_5 from '../assets/projects/musure.png'
import logo_6 from '../assets/projects/vice.png'
import logo_7 from '../assets/projects/pap.png'

const Projects = () => {
    return (
        <Row className="projects mx-0 p-0" id="projects">
            <Row className="m-0 p-0">
                <div className="sectionTitle reveal">
                    Recent Projects
                </div>
            </Row>
            <Row className="projectItems m-0 ">
                <Project light logo={logo_7} title="Prime Ape Planet" targetBlank 
                link="https://linktr.ee/primeplanet" linkText="Visit Website >"
                description="Linkolabs was responsible for the Burn phase of the large scale Prime Ape Planet project. This crucial event allowed holders to burn their Ape NFTs in order to receive points and redeem more powerfull NFTs in the form of Dragon Eggs." />
                <Project logo={logo_4} title="Mystic Motors NFT" targetBlank 
                link="https://mysticmotorsmint.com/" linkText="Visit Website >"
                description="Mystic Motors is an action-packed car racing game where players race their unique NFT cars to earn real money. Due to client-side urgency, we had to implement the NFT smart contract in a record time of 24 hours as well as the minting integration on the React Website. The collection went soldout in under 3 minutes." />
                <Project light logo={logo_5} title="Musure World" 
                // linkText="Visit Website >" link="https://www.origin.musure.world/" 
                targetBlank description="A complex NFT ecosystem similar to the RTFKT MNLTH box and loot systems. Includes randomization, opening boxes to loot NFTs, combining NFTs to forge new ones and a custom inventory dashboard." />
                <Project logo={logo_3} link="/project-gelatoverse" title="Gelatoverse Staking" linkText="Read more >" description="This NFT project has many interesting features that we had the chance to implement, including: Staking with ERC-20 reward, MerkleTree for whitelisting, revealing unknown single NFTs at any moment after deployment." />
                <Project light logo={logo_2} link="/project-aka-thailand" title="AKA Thailand Audit" linkText="Learn more about my auditing reports >" description="This NFT project is lead by former UFC Mike Swick  and AKA Thailand with a lifetime membership utility. We were contracted for this and many Web3 projects to perform smart contract auditings." />
                <Project logo={logo_6} title="Vice Palace Casino" targetBlank
                // link="https://vicetoads.io/" linkText="Visit Website >"
                description="On top of building the entire website and NFT collection for this MultiversX project, we had the privilege to create an entire Casino ecosystem with a Lottery and a Coinflip game. The randomness is secure, the games support a demo mode, optional free spins and volume tracking." />
                <Project light logo={logo_1} link="/project-raffle" title="Raffle Game" linkText="Read more >" description="A raffle dApp on the Ethereum Classic network. Players enter the raffle by betting a certain amount of a custom token and can be rewarded with those. An interesting use of the provenance hash algorithm was implemented for random number verification." />
            </Row>
        </Row>
    );
}
export default Projects