import { Image, Row, Col, Button } from 'react-bootstrap'
import Offer from './Offer'
import Tool from './Tool'
import logo_1 from '../assets/offers/consulting.png'
import logo_2 from '../assets/offers/nft_project.png'
import logo_3 from '../assets/offers/dapp.png'
import logo_4 from '../assets/offers/web3_integration.png'
import logo_5 from '../assets/offers/auditing.png'
import logo_6 from '../assets/offers/game.png'
import tool_1 from '../assets/tools/solidity.png'
import tool_2 from '../assets/tools/react.png'
import tool_3 from '../assets/tools/nodejs.png'
import tool_4 from '../assets/tools/hardhat.png'
import tool_5 from '../assets/tools/chai.png'
import tool_6 from '../assets/tools/slither.png'

const Offers = () => {
    return (
        <Row className="offers mx-0" id="offers">
            <Row className=" mx-0">
                <div className="sectionTitle reveal">
                    What we offer
                </div>
            </Row>
            <Row className=" mx-0">
                <div className="sectionDescription reveal">
                    Join the trend and create your decentralized application today.
                    <br/>We will build Ethereum-based smart contracts, as well as the frontend and backend of your dApp!
                </div>
            </Row>
            <Row className="offerLogos mx-0 justify-content-center align-items-center">
                <Offer logo={logo_1} title="Consulting" description="Let us answer any technical questions and guide you through your roadmap." />
                <Offer logo={logo_2} light title="NFT Project" description="Let us build and deploy your NFT project to the Blockchain." />
                <Offer logo={logo_3} title="dApp" description="We will create your web-based decentralized application." />
            </Row>
            <Row className="offerLogos mx-0 justify-content-center align-items-center">
                <Offer logo={logo_4} light title="Web3 Integration" description="Already have a website but you want to switch to Web3? We can work this out!" />
                <Offer logo={logo_5} title="Auditing" description="Solidity Smart Contract Auditing" link={"Check out our Auditing Process >"} linkHref={"auditing-process"} />
                <Offer logo={logo_6} light title="Web3 Game" description="Time to enter the metaverse. What’s your game idea?" />
            </Row>
            <Row className="tools mx-0">
                <Row className=" mx-0">
                    <div className="sectionSubtitle mx-0 reveal">
                    Our main Tools
                    </div>
                </Row>
                <Row className="toolItems mx-0 justify-content-center align-items-center">
                    <Tool logo={tool_1} title="Solidity" />
                    <Tool logo={tool_2} title="ReactJs" />
                    <Tool logo={tool_3} title="NodeJs" />
                </Row>
                <Row className="toolItems mx-0 justify-content-center align-items-center">
                    <Tool logo={tool_4} title="Hardhat" />
                    <Tool logo={tool_5} title="Chai" />
                    {/* <div className="m-0 p-0 d-none d-lg-block"><Tool className="" logo={tool_6} title="Slither" /></div> */}
                </Row>
            </Row>
        </Row>
    );
}
export default Offers