import { Image, Row, Col } from 'react-bootstrap'
const Offer = ({logo, title, description, light, link, linkHref}) => {
    return (
        <div className={light ? "offer offerLightBackground revealPop" : "offer revealPop"}>
            <div className="offerImage"><Image src={logo} className="offerLogo" /></div>
            <div className="offerTitle">{title}</div>
            <div className="offerDescription">
                {description}
                {link ? (
                    <a className="offerLink" href={linkHref}><br/><br/>{link}</a>
                ) : (
                    <></>
                )}
            </div>
            {/* <div className="offerLink">{link}</div> */}
        </div>
    );
}
export default Offer