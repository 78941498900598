import './App.css';
import { Row, Col, Image } from 'react-bootstrap'
import Tool from './Tool'
import Contact from './Contact'
import tool_1 from '../assets/tools/solidity.png'
import tool_2 from '../assets/tools/react.png'
import tool_3 from '../assets/tools/nodejs.png'
import tool_4 from '../assets/tools/hardhat.png'
import tool_5 from '../assets/tools/chai.png'
import tool_6 from '../assets/tools/slither.png'
import nfts from '../assets/project_pages/gelatoverse/nfts.png'
import merkle_tree from '../assets/project_pages/gelatoverse/merkle_tree.png'
import staking from '../assets/project_pages/gelatoverse/staking.png'
import unit_tests from '../assets/project_pages/gelatoverse/unit_tests.jpg'

const Project_Gelatoverse = () => {
  return (
    <Row className="projectPage">

      {/* HEADER */}
      <Row className="projectPageHeader projectPageHeaderGelatoverse">
      </Row>

      {/* CONTENT */}
      <Row className="projectPageContent">

        {/* TITLE */}
        <Row className="projectPageTitle">
          <div>
            Gelatoverse
          </div>
        </Row>
        <Row className="projectPageSubtitle">
          <div>
            NFT Project with Staking and individual reveal features
          </div>
        </Row>

        {/* PROPERTIES */}
        <Row className="projectPageProperties">
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Project</div>
              <div className="projectPagePropertyDesc">ERC721A and ERC20 Project with Staking</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Category</div>
              <div className="projectPagePropertyDesc">NFT Staking</div>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">For who?</div>
              <div className="projectPagePropertyDesc">A Swiss startup</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Role</div>
              <div className="projectPagePropertyDesc">Blockchain Developer</div>
            </Row>
          </Col>
        </Row>


        {/* TOOLS */}
        <Row className="tools mx-0">
            <Row className=" mx-0">
                <div className="sectionSubtitle mx-0 reveal">
                    Used Tools
                </div>
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_1} title="Solidity" />
                <Tool logo={tool_2} title="ReactJs" />
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_4} title="Hardhat" />
                <Tool logo={tool_5} title="Chai" />
                <Tool logo={tool_6} title="Slither" />
            </Row>
        </Row>

        {/* DESCRIPTION */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-8 p-0">
            <div className="projectPageDescriptionTitle">
              The Project
            </div>
            <div className="projectPageDescriptionText">
              Glatoverse is a NFT collection that allows holders to stake their NFTs in exchange for ERC-20 Token over time. The owner can start missions at will with specific durations. During these missions, holders can send their Gelato "in a mission" and then earn ERC-20 tokens in return. 
              
              {/* <br/><br/>The Web3 implementation on the frontend also required a significant amount of work. It included listening to blockchain events to update the frontend live, calling OpenSea to retreive metadata and fancy datastructure manipulations. */}
            </div>
          </Col>
          <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={nfts} className="projectPageImageElement" /></div>
            </div>
          </Col>
        </Row>

        {/* STAKING */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={staking} className="projectPageImageElementMid" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle alignRightOnDesktop">
                NFT Staking 
              </div>
              <div className="projectPageDescriptionText">
                The most interesting feature of this NFT collection was the Staking. The owner of the collection can start a mission at any time and specify a duration. As long as the mission is ongoing, NFT holders can send their NFT on a mission (as in stake them) and earn ERC-20 rewards over time.<br/><br/>
                Having a utility for your NFT collection is crucial nowadays and can give the holders a sense of purpose and future usage in the NFT collection.
              </div>
            </Col>
          </Row>
        </Row>

        {/* WHITELIST MERKLETREE */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-7 p-0">
            <div className="projectPageDescriptionTitle">
                Whitelist with MerkleTree
            </div>
            <div className="projectPageDescriptionText">
                Saving a whitelist array with a huge size on the blockchain is very costly! Thankfully we can use a MerkleTree for whitelists to save gas in this NFT collection. <br/><br/>
                Merkle Trees are a tree-like structure where every node on the tree is represented by a value that is the result of some cryptographic hash function. Hash functions are 1-way, meaning it is easy to produce an output from an input, but computationally infeasible to determine an input from an output. <br/><br/>
                For you as a NFT project creator, it means that a Merkle Tree will be created out of your whitelist and only the root hash will be used as proof that a wallet address belongs to it. Cryptography is great!
            </div>
          </Col>
          <Col className="col-12 col-lg-5 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={merkle_tree} className="projectPageImageElementMid" /></div>
            </div>
          </Col>
        </Row>

        {/* UNIT TESTS */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={unit_tests} className="projectPageImageElement" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle alignRightOnDesktop">
                Unit Tests
              </div>
              <div className="projectPageDescriptionText">
                Writing automated tests when building smart contracts is of crucial importance, as your user's money is what's at stake. <br/> <br/>
                The complexity in this project necessited a very good coverage in unit tests. Manipulating time in these unit tests ensured that every situation could be covered and make sure that the code was behaving as wished.
              </div>
            </Col>
          </Row>
        </Row>

      </Row>
      <Contact className="mt-5" text={"You see something you like? Don't hesitate to reach out to get a Quote estimation for FREE."}/>
    </Row>
  );
}

export default Project_Gelatoverse;
