import './App.css';
import { Row, Col, Image } from 'react-bootstrap'
import Tool from './Tool'
import Contact from './Contact'
import tool_1 from '../assets/tools/solidity.png'
import tool_4 from '../assets/tools/hardhat.png'
import tool_5 from '../assets/tools/chai.png'
import tool_6 from '../assets/tools/slither.png'
import unit_tests from '../assets/project_pages/gelatoverse/unit_tests.jpg'
import report from '../assets/project_pages/auditing/report.png'
import discuss from '../assets/project_pages/auditing/discuss.png'
import analysis from '../assets/project_pages/auditing/analysis.png'
import fast from '../assets/project_pages/auditing/fast.png'
import documentation from '../assets/project_pages/auditing/documentation.png'
import tests from '../assets/project_pages/auditing/tests.png'
import slow from '../assets/project_pages/auditing/slow.png'

const AuditingProcess = () => {
  return (
    <Row className="projectPage">

      {/* HEADER */}
      <Row className="projectPageHeader projectPageHeaderAuditingProcess">
      </Row>

      {/* CONTENT */}
      <Row className="projectPageContent">

        {/* TITLE */}
        <Row className="projectPageTitle">
          <div>
            Smart Contract Auditing
          </div>
        </Row>
        <Row className="projectPageSubtitle">
          <div>
            Our Process
          </div>
        </Row>

        {/* PROPERTIES */}
        {/* <Row className="projectPageProperties">
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Project</div>
              <div className="projectPagePropertyDesc">ERC721A Smart Contract Auditing</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Category</div>
              <div className="projectPagePropertyDesc">ERC721A NFT Project</div>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 m-0 p-0">
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">For who?</div>
              <div className="projectPagePropertyDesc">AKA Thailand in collaboration with Mike Swick, famous former UFC performer</div>
            </Row>
            <Row className="projectPagePropertyRow">
              <div className="projectPagePropertyTitle">Role</div>
              <div className="projectPagePropertyDesc">Smart Contract Auditor</div>
            </Row>
          </Col>
        </Row> */}


        {/* TOOLS */}
        <Row className="tools mx-0">
            <Row className=" mx-0">
                <div className="sectionSubtitle mx-0 reveal">
                    Used Tools
                </div>
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_1} title="Solidity" />
                <Tool logo={tool_4} title="Hardhat" />
            </Row>
            <Row className="toolItems mx-0 justify-content-center align-items-center">
                <Tool logo={tool_5} title="Chai" />
                <Tool className="" logo={tool_6} title="Slither" />
            </Row>
        </Row>

        {/* DESCRIPTION */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-8 p-0">
            <div className="projectPageDescriptionTitle">
              What is Smart Contract Auditing?
            </div>
            <div className="projectPageDescriptionText">
              A smart contract security audit provides a <span className="highlightBold">detailed analysis of a project's smart contracts</span>. These are important to safeguard funds invested through them. As all transactions on the blockchain are final, funds cannot be retrieved should they be stolen. Typically, auditors will examine the code of smart contracts, produce a report, and provide it to the project for them to work with. A <span className="highlightBold">final report</span> is then released, detailing any outstanding errors and the work already done to address performance or security issues. <br/><br/>
              Another motivation is finding ways to <span className="highlightBold">improve the gas efficiency</span> on the Ethereum network and so save gas fees when calling functions in the smart contract.<br/> <br/>
              Our auditing process looks like the following: <br/> <br/>
              1. Run tests <br/>
              2. Read specs/docs <br/>
              3. Run fast tools (like slither, linters, static analysis, etc) <br/>
              4. Manual Analysis <br/>
              5. Run slow tools (like echidna, manticore, symbolic execution, MythX) <br/>
              6. Discuss (and repeat steps as needed) <br/>
              7. Write report

              <br/><br/>In the end of the auditing process you will receive a .PDF report containing our analysis. You will see the found errors, categorized in severity, as well as notes regarding the overall behavior of the code in comparison to your requirements.
            </div>
          </Col>
          <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={unit_tests} className="projectPageImageElement" /></div>
            </div>
          </Col>
        </Row>

        {/* AUDITING PROCESS */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={tests} className="projectPageImageElementMid" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle alignRightOnDesktop">
                1. Run tests
              </div>
              <div className="projectPageDescriptionText">
                It is highly recommended that your existing smart contract code is covered by automatic unit tests. These help cover as many user-flow scenarios as possible in a programmatical way and verify that everything works as expected at any time by the push of a button. 
                <br/><br/>Not having any unit tests written is a substantial red flag regarding the quality of a blockchain developer and can almost guarantee security issues down the line.
              </div>
            </Col>
          </Row>
        </Row>

        {/* DESCRIPTION */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-8 p-0">
            <div className="projectPageDescriptionTitle">
              2. Read Specs & docs
            </div>
            <div className="projectPageDescriptionText">
              As a Smart Contract Auditor, it is important that we understand the goal intended behind the code of the smart contracts. What is the project trying to achieve?
              <br/><br/>Providing rich and precise information regarding your vision will help us as an auditor to find potential behaviors in the code that do not reflect your intentions.
            </div>
          </Col>
          <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={documentation} className="projectPageImageElement" /></div>
            </div>
          </Col>
        </Row>
        
        {/* AUDITING PROCESS */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={fast} className="projectPageImageElementMid" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle alignRightOnDesktop">
                3. Run Fast Tools
              </div>
              <div className="projectPageDescriptionText">
                There are many tools in the arsenal of a Smart Contract Auditor that perform a so-called static analysis. Many standard errors and security breaches can be spotted that way and categorized into different severities.
                <br/><br/>Smart Contract Auditors will have the expertise to translate the reported errors into understandable actions to take for you in order to fix the potential detected issues.
              </div>
            </Col>
          </Row>
        </Row>

        {/* DESCRIPTION */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-8 p-0">
            <div className="projectPageDescriptionTitle">
              4. Manual Analysis
            </div>
            <div className="projectPageDescriptionText">
              The understanding of the project will play a big role here. The Smart Contract Auditor will read the code line by line and spot behavior and logic mistakes manually. This is also where the most of the gas efficiency issues are found in the code.
            </div>
          </Col>
          <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={analysis} className="projectPageImageElement" /></div>
            </div>
          </Col>
        </Row>
        
        {/* AUDITING PROCESS */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={slow} className="projectPageImageElementMid" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle alignRightOnDesktop">
                5. Run Slow Tools
              </div>
              <div className="projectPageDescriptionText">
                An effective way to maximize code coverage in software tests is through dynamic symbolic execution—a technique that uses constraint solving to systematically explore a program’s state space. E.g. Manticore’s flexible architecture allows it to support both traditional and exotic execution environments, and its API allows to customize our analysis.
              </div>
            </Col>
          </Row>
        </Row>

        {/* DESCRIPTION */}
        <Row className="projectPageDescription reveal">
          <Col className="col-12 col-lg-8 p-0">
            <div className="projectPageDescriptionTitle">
              6. Discuss
            </div>
            <div className="projectPageDescriptionText">
              A constant communication is kept with you for an in-depth understanding and most precise analysis of your smart contracts. The goal here is to understand the decisions that were taken by you in some cases and provide you with suggestions along the way.
            </div>
          </Col>
          <Col className="col-12 col-lg-4 p-0 d-flex justify-content-center align-items-center">
            <div className="projectPageDescriptionImage">
              <div className="projectPageImage my-auto"><Image src={discuss} className="projectPageImageElement" /></div>
            </div>
          </Col>
        </Row>
        
        {/* AUDITING PROCESS */}
        <Row className="projectPageDescription reveal">
          <Row className="m-0 p-0">
            <Col className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
              <div className="projectPageDescriptionImage">
                <div className="projectPageImage my-auto"><Image src={report} className="projectPageImageElementMid" /></div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 p-0">
              <div className="projectPageDescriptionTitle alignRightOnDesktop">
                7. Write Report
              </div>
              <div className="projectPageDescriptionText">
                In the end of the auditing process you will receive a .PDF report containing our analysis. You will see the found errors, categorized in severity, as well as notes regarding everything that was done in this process and the overall behavior of the code in comparison to your requirements.
              </div>
            </Col>
          </Row>
        </Row>

      </Row>
      <Contact className="mt-5" text={"You see something you like? Don't hesitate to reach out to get a Quote estimation for FREE."}/>
    </Row>
  );
}

export default AuditingProcess;
