import { Row, Col, Form, Button } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import React from "react"
import { useState, useEffect } from 'react'
import emailjs from 'emailjs-com';

const Contact = ({text}) => {
    const [messageButton, setMessageButton] = useState("Send Message")
    const [messageSent, setMessageSent] = useState(false)
    const recaptchaRef = React.createRef();
    const considerCaptcha = true

    const sendEmail = (e) => {
        console.log("sendEmail")

        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        console.log("Captcha Value: " + recaptchaValue)

        if (considerCaptcha && (messageSent || recaptchaValue == null || recaptchaValue.length == 0)) {
            console.log("Captcha value invalid!")
            alert("Captcha value invalid!");
            return
        }

        const emailInput = e.target.formBasicEmail;
        const nameInput = e.target.formFullName;
        const messageInput = e.target.formMessage;

        console.log("Name: " + nameInput.value);
        console.log("Email: " + emailInput.value);
        console.log("Message: " + messageInput.value);
        
        window.gtag_report_conversion_contact()
        window.lintrk('track', { conversion_id: 12135332 });

        setMessageButton("Sending...")
        setMessageSent(true)
        
        // console.log("key: " + process.env.REACT_APP_EMAILJS_API_KEY)
        emailjs.send("service_3f9ixg5","template_ut7ncmo",{
            from_name: nameInput.value,
            message: messageInput.value,
            reply_to: emailInput.value,
            'g-recaptcha-response': recaptchaValue
        }, process.env.REACT_APP_EMAILJS_API_KEY).then(() => {
            setMessageButton("Send Message")
            alert('Thank you for your message!');
          }, (err) => {
            setMessageButton("Send Message")
            let errorMsg = "Error when sending message: " + JSON.stringify(err)
            alert(errorMsg);
          });
      }

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        <Row className="contact" id="contact">
            <Row className="m-0">
                <div className="sectionTitle reveal">
                    Contact Us
                </div>
            </Row>
            <Row className="m-0">
                <div className="sectionDescription reveal">
                    {text}
                </div>
            </Row>
            <Row className="m-0">
                <div className="contactForm mx-auto my-0 p-0 revealPop">
                    <Form onSubmit={sendEmail}>
                        <Form.Group className="mb-3" controlId="formFullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control placeholder="Dan Joe" required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="dan.joe@mail.com" required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formMessage">
                            <Form.Label>Your Message</Form.Label>
                            <Form.Control as="textarea" rows={6} required />
                        </Form.Group>
                        
                        <Row className="my-0 mx-auto p-0 contactSendMessageDiv align-items-center">
                            <Col className="p-0 col-12 col-xl-8 align-items-center">
                                <Form.Group className="mb-3" controlId="formCapcha">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LdlYMsgAAAAAEcaT9F4A1q7yDO0iKOdJl75snC0"
                                        onChange={onChange}
                                        className="reCaptcha"
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="p-0 col-12 col-xl-4 justify-content-end">
                                <Button className="sendMessageButton" type="submit">{messageButton}</Button>
                            </Col>
                        </Row>
                        
                    </Form>
                </div>
            </Row>
        </Row>
    );
}
export default Contact